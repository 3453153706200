<template>
  <div class="appBackGround">
    <v-divider class="ml-3"></v-divider>
    <div class="font-14 fw-600 ml-3 mt-6 mb-1 greyColor--text">API Keys</div>
    <div class="api-main-keys pa-3">
      <label class="font-12 fw-500 secondary--text">Client ID</label>
      <div class="api-standard-input mb-4">
        <v-text-field
          v-model="clientKey"
          type="text"
          readonly
          outlined
          height="44"
          hide-details
          dense
          class="api-keys-field font-12 fw-400 mt-2"
          append-icon="mdi-checkbox-multiple-blank-outline"
          @click:append="copyClientKey(clientKey)"
        ></v-text-field>
      </div>
      <label class="font-12 fw-500 secondary--text">New Secret Key</label>
      <div class="api-standard-input d-flex mb-4">
        <v-text-field
          v-if="!isKeysUnlocked"
          v-model="clientSecret"
          type="password"
          outlined
          height="44"
          hide-details
          dense
          class="api-keys-field font-12 fw-400 mt-2"
        ></v-text-field>
        <v-text-field
          v-if="isKeysUnlocked"
          v-model="clientSecret"
          type="text"
          outlined
          height="44"
          hide-details
          dense
          class="api-keys-field font-12 fw-400 mt-2"
          append-icon="mdi-checkbox-multiple-blank-outline"
          @click:append="copyClientSecret(clientSecret)"
        ></v-text-field>
        <a
          v-if="isKeysUnlocked"
          href="javascript:void(0);"
          @click="regenerateKey"
          class="font-12 mt-4 ml-5 fw-400 primary--text"
          >Generate Key</a
        >
      </div>
    </div>
    <div class="mt-5 ml-4">
      <v-btn
        v-if="!isKeysUnlocked"
        elevation="0"
        color="primary"
        width="146"
        height="40"
        class="textColor--text letter-space user-btns text-transform-none font-13 fw-500 mr-3"
        @click="unlockKeys"
        >Unlock Keys</v-btn
      >
      <v-btn
        v-else
        elevation="0"
        color="primary"
        width="146"
        height="40"
        class="textColor--text letter-space user-btns text-transform-none font-13 fw-500 mr-3"
        @click="hideKeys"
        >Hide Keys</v-btn
      >
      <!-- <v-btn
        elevation="0"
        color="#F2F4F9"
        width="146"
        height="40"
        class="secondary--text user-btns text-transform-none font-13 fw-500 mr-3"
        >Dsicard</v-btn
      > -->
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/Api";
import { showErrorMessage, showNotificationBar } from "@/utils/showError";
export default {
  data() {
    return {
      clientKey: "",
      clientSecret: "",
      isKeysUnlocked: false,
    };
  },
  methods: {
    /**
     * Unlocks the keys.
     */
    unlockKeys() {
      this.isKeysUnlocked = true;
    },

    /**
     * Hides the keys.
     */
    hideKeys() {
      this.isKeysUnlocked = false;
    },

    /**
     * Copies the client secret to the clipboard and shows a success notification.
     * @param {string} secret - The client secret to copy.
     */
    copyClientSecret(secret) {
      navigator.clipboard.writeText(secret);
      showNotificationBar("success", "Client secret key has been copied!");
    },

    /**
     * Copies the client ID to the clipboard and shows a success notification.
     * @param {string} id - The client ID to copy.
     */
    copyClientKey(id) {
      navigator.clipboard.writeText(id);
      showNotificationBar("success", "Client id has been copied!");
    },

    /**
     * Fetches the client key from the server.
     * Updates the client key and client secret in the component data.
     */
    getClientKey() {
      ApiService.GetRequest("/merchant/client")
        .then((res) => {
          this.clientKey = res.data.result.data.client_id;
          this.clientSecret = res.data.result.data.client_secret;
        })
        .catch((error) => {
          showErrorMessage(error);
        });
    },

    /**
     * Regenerates the secret key.
     * Updates the client key and client secret in the component data after regeneration.
     */
    regenerateKey() {
      ApiService.GetRequest("/merchant/regenerate-secret-key")
        .then((res) => {
          this.$store.dispatch(
            "UPDATE_CLIENT_SECRET",
            res.data.result.data.client_secret
          );
          this.clientKey = res.data.result.data.client_id;
          this.clientSecret = res.data.result.data.client_secret;
        })
        .catch((error) => {
          showErrorMessage(error);
        });
    },
  },
  mounted() {
    this.getClientKey();
  },
};
</script>

<style>
.api-keys-field .v-input__slot {
  max-width: 466px !important;
}
.api-keys-field.v-input {
  max-width: 500px !important;
}
.api-keys-field fieldset {
  border: 1px solid #e1e4ec !important;
  border-radius: 6px;
}
.api-keys-field .v-icon {
  font-size: 22px !important;
  color: #a0a6b4 !important;
}
.api-keys-field .v-icon::before {
  font-size: 22px !important;
  color: #a0a6b4 !important;
}
</style>
